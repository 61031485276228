import { useTranslation } from "next-i18next";
import Image from "next/image";
import { FC } from "react";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { customerFeedbacks } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./CustomerFeedback.module.scss";
import InfiniteScroll from "~/components/InfiniteScroll";

const FeedbackItem: FC<{ feedback: any }> = ({ feedback }) => {
  return (
    <div className={styles.feedback}>
      <div className="flex items-center gap-3">
        <Image
          className={styles.avatar}
          src={feedback.avatar}
          alt={feedback.name}
          width={48}
          height={48}
        />

        <div className="flex flex-col flex-1 gap-1">
          <Text type="title-3" color={Colors.GREY_800}>
            {feedback.name}
          </Text>

          <div className="flex items-center gap-1">
            <Image
              src="/svgs/verify_fill.svg"
              alt="Verify"
              width={20}
              height={20}
            />
            <Text type="body-2" color={Colors.GREY_500}>
              Verified purchaser
            </Text>
          </div>
        </div>

        <div className="flex items-center gap-1">
          <Icon name="star-fill" color={Colors.ACCENT_YELLOW} />
          <Text type="title-3" color={Colors.GREY_800}>
            {feedback.star}
          </Text>
        </div>
      </div>

      <div className={styles.divider} />

      <Text type="title-2" color={Colors.GREY_600} className="!leading-normal">
        {feedback.title}
      </Text>

      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: feedback.description }}
      />
    </div>
  );
};

const CustomerFeedback: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.customer_feedback}>
      <div className={styles.container}>
        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.feedback_title)}
        </h2>

        <InfiniteScroll
          height={593}
          items={[
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[0]} />
              <FeedbackItem feedback={customerFeedbacks[7]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[3]} />
              <FeedbackItem feedback={customerFeedbacks[5]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[6]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[1]} />
              <FeedbackItem feedback={customerFeedbacks[8]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[4]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[9]} />
              <FeedbackItem feedback={customerFeedbacks[2]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[10]} />
              <FeedbackItem feedback={customerFeedbacks[11]} />
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

export default CustomerFeedback;
